import { render, staticRenderFns } from "./ConsolidationWidget.vue?vue&type=template&id=a1bec534&scoped=true"
import script from "./ConsolidationWidget.vue?vue&type=script&lang=js"
export * from "./ConsolidationWidget.vue?vue&type=script&lang=js"
import style0 from "./ConsolidationWidget.vue?vue&type=style&index=0&id=a1bec534&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1bec534",
  null
  
)

export default component.exports